import storage from '@/utils/storage';
import { Code } from 'lucide-react';
import { useState } from 'react';

export function DeviceIdDialog() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div onDoubleClick={() => setIsOpen(true)}>
        <button className="z-30 fixed bottom-0 left-0 h-[5hv] w-[5vw]  text-transparent">Device ID</button>
      </div>
      <dialog open={isOpen} className="fixed z-40 !mt-0 inset-0 bg-black w-screen h-screen  bg-opacity-50">
        <div className=" bg-white p-6 rounded-lg space-y-6 w-fit z-50 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="flex items-center">
            <Code className="w-6 h-6 mr-2" />
            <h3 className="text-lg font-semibold">{`Ditt enhets-ID är ${storage.getDeviceId() ?? 'okänt'}`}</h3>
          </div>

          <p>Du kan ange det här ID:t för att konfigurera en skärm från administratörsgränssnittet. Om du inte har ett ID än, vänligen anslut enheten till internet.</p>

          <div className="flex justify-end gap-x-4 items-center font-medium">
            <button onClick={() => setIsOpen(false)}>Stäng</button>
          </div>
        </div>
      </dialog>
    </>
  );
}
