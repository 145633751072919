import ImageBlock from './ImagePageItem';
import TextBlock from './TextPageItem';
import VideoBlock from './VideoPageItem';
import { PageItem, PageItemType } from '@/types';
import { YoutubePageItem } from './YoutubePageItem';
import BookingListPageItem from './BookingListPageItem';
import BookingPageItem from './BookingPageItem';
import CarouselBlock from './CarouselPageItem';

interface IBlockProps {
  pageItem: PageItem;
  rotation?: number;
}

const blocks = {
  [PageItemType.Image]: ImageBlock,
  [PageItemType.Text]: TextBlock,
  [PageItemType.Video]: VideoBlock,
  [PageItemType.Youtube]: YoutubePageItem,
  [PageItemType.BookingList]: BookingListPageItem,
  [PageItemType.Booking]: BookingPageItem,
  [PageItemType.Carousel]: CarouselBlock,
} as const;

export function PageItem({ pageItem, rotation }: IBlockProps) {
  const { type, settings } = pageItem;

  const BlockComponent = blocks?.[type as keyof typeof blocks];

  if (!BlockComponent) return <span>Uknown block {type}</span>;

  if (typeof settings === 'string') {
    return <BlockComponent {...JSON.parse(settings)} rotation={rotation} />;
  }

  return <BlockComponent {...({ ...settings } as any)} rotation={rotation} />;
}
