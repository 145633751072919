import { useEffect, useRef, useState } from 'react';
import { Playlist, TransitionType } from '../types';
import { Page } from './Page';
import { ThemeWrapper } from './ThemeWrapper';
import { ForceDisconnectButton } from './ForceDisconnectButton';
import { DeviceIdDialog } from './DeviceIdDialog';

interface PlayListProps {
  playlist: Playlist;
}

function PlayList({ playlist }: PlayListProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [theme, setTheme] = useState(playlist?.playlistItems?.[currentIndex]?.page?.theme?.css);
  const intervalRef = useRef<any>(null);

  const pages = playlist?.playlistItems?.sort((a, b) => a.sortOrder - b.sortOrder)?.map(playlistItem => playlistItem.page) ?? [];
  const currentPage = pages?.[currentIndex];

  const transitionIn = playlist?.playlistItems?.[currentIndex]?.transitionIn ?? TransitionType.Ease;

  useEffect(() => {
    setCurrentIndex(0);
    clearInterval(intervalRef.current);
  }, [playlist?.playlistId]);

  useEffect(() => {
    const playlistItem = playlist?.playlistItems?.sort((a, b) => a.sortOrder - b.sortOrder)?.[currentIndex];
    const duration = playlistItem?.duration ?? 10;

    const interval = setInterval(() => {
      const nextIndex = currentIndex === pages.length - 1 ? 0 : currentIndex + 1;
      const nextPlaylistItem = playlist?.playlistItems?.sort((a, b) => a.sortOrder - b.sortOrder)?.[nextIndex];
      const nextTheme = nextPlaylistItem?.page?.theme?.css ?? undefined;
      const themeDelay = playlist?.playlistItems?.[currentIndex]?.transitionOut === 'linear' ? 0 : 50;

      setCurrentIndex(currentIndex => {
        if (currentIndex === pages.length - 1) {
          return 0;
        }
        return currentIndex + 1;
      });

      if (nextPlaylistItem?.page?.theme?.themeId !== playlistItem?.page?.theme?.themeId) {
        setTimeout(() => {
          setTheme(nextTheme);
        }, themeDelay);
      }

      clearInterval(intervalRef.current);
    }, duration * 1000);

    intervalRef.current = interval;

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [currentIndex, playlist?.playlistItems]);

  return (
    <>
      <div className="w-full h-screen bg-black">
        {pages?.map(page => (
          <div
            className={`
              absolute top-0 left-0 w-full h-full 
             ${page?.pageId === currentPage?.pageId ? 'opacity-100  z-10' : 'opacity-0 z-0'}
             ${transitionIn !== 'linear' ? 'duration-1000 transition-opacity ' : ' '}
            `}
            key={page?.pageId}
          >
            <ThemeWrapper key={page?.pageId} config={theme as any}>
              {page && <Page page={page} />}
            </ThemeWrapper>
          </div>
        ))}
      </div>
      <DeviceIdDialog />
      <ForceDisconnectButton />
    </>
  );
}

export default PlayList;
