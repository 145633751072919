import { useEffect, useState } from 'react';
import { Image } from '../../types';
import { ImageOff } from 'lucide-react';
import VideoBlock from './VideoPageItem';
import ImageBlock from './ImagePageItem';

interface CarouselBlockProps {
  media: Image[];
  backgroundColor: string;
}

const MediaComponent = (filename: string) => {
  const extension = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);

  switch (extension) {
    case 'mp4':
    case 'webm':
    case 'ogv':
    case 'mov':
      return VideoBlock;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'svg':
      return ImageBlock;
    default:
      return () => (
        <div className="w-full h-full bg-gray-200 grid place-items-center">
          <span className="text-gray-700 text-md font-semibold flex items-center gap-x-2">
            <ImageOff className="w-8 h-8" />
            Ingen media tillagd
          </span>
        </div>
      );
  }
};

function CarouselBlock(props: CarouselBlockProps) {
  const { media = [], backgroundColor } = props;

  const [index, setIndex] = useState(0);

  const currentMedia = media[index];

  useEffect(() => {
    if (media.length === 0) {
      return;
    }

    const imageDuration = !isNaN(media[index].duration) ? media[index].duration : 5;

    const interval = setInterval(() => {
      setIndex(prev => (prev + 1) % media.length);
    }, imageDuration * 1000);

    return () => clearInterval(interval);
  }, [media, index]);

  if (media.length === 0 || !currentMedia) {
    return (
      <div className="w-full h-full bg-gray-200 grid place-items-center">
        <span className="text-gray-700 text-md font-semibold flex items-center gap-x-2">
          <ImageOff className="w-8 h-8" />
          Inga bilder tillagda
        </span>
      </div>
    );
  }

  const Media = MediaComponent(currentMedia.src);

  return (
    <div
      className="relative w-full h-full"
      style={{
        backgroundColor,
      }}
    >
      <Media src={currentMedia.src} alt="" objectFit={currentMedia.objectFit as any} backgroundColor="transparent" />
    </div>
  );
}

export default CarouselBlock;
