import { ENV } from '@/config';
import { useEffect, useState } from 'react';

interface ImageBlockProps {
  src: string;
  alt: string;
  objectFit: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  backgroundColor: string;
  rotation?: number;
}

function ImageBlock(props: ImageBlockProps) {
  const [imgSrc, setImgSrc] = useState(props.src); // [1
  const { src, alt, objectFit = 'cover', backgroundColor = '#fff' } = props;

  useEffect(() => {
    async function getImageFromCache(src: string) {
      try {
        if (ENV === 'debug') {
          setImgSrc(src);
          return;
        }

        // Check if the image URL is cached in localStorage
        const cachedImgSrc = localStorage.getItem(src);
        if (cachedImgSrc) {
          console.log('Image found in cache');
          setImgSrc(cachedImgSrc);
        } else {
          console.log('Image not found in cache');
          localStorage.setItem(src, src); // Cache the image URL
          setImgSrc(src);
          // Handle the case when the image is not found in cache
          // You might want to fetch the image from the network here
          // and cache it in localStorage for future use
        }
      } catch (error) {
        console.error('Error getting image from cache', error);
        setImgSrc(src); // Fallback to the original image URL
        localStorage.setItem(src, src); // Cache the image URL
      }
    }
    getImageFromCache(src);
  }, [src]); // Execute useEffect when src changes

  return (
    <img
      className="w-full h-full object-cover"
      src={imgSrc ?? src}
      alt={alt}
      style={{
        objectFit,
        backgroundColor,
      }}
    />
  );
}

export default ImageBlock;
