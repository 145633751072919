import { AlertTriangle } from 'lucide-react';
import { useState } from 'react';

interface ConfirmationDialogProps {
  onConfirm: () => void;
  ButtonComponent: React.ReactNode;
}

export function ConfirmationDialog({ onConfirm, ButtonComponent }: ConfirmationDialogProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div onDoubleClick={() => setIsOpen(true)}>{ButtonComponent}</div>
      <dialog open={isOpen} className="fixed z-40 inset-0 bg-black w-screen h-screen  bg-opacity-50">
        <div className=" bg-white p-6 rounded-lg space-y-6 w-fit z-50 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="flex items-center">
            <AlertTriangle className="w-6 h-6 mr-2" />
            <h3 className="text-lg font-semibold">Är du säker att du vill återställa skärmen?</h3>
          </div>
          <p>Skärmen kommer tömmas men du kan alltid återkonfigurera den. </p>
          <div className="flex justify-end gap-x-4 items-center font-medium">
            <button onClick={() => setIsOpen(false)} >
              Avbryt
            </button>
            <button
              className="bg-amber-600 text-white py-1.5 px-3 rounded-md"
              onClick={() => {
                onConfirm();
                setIsOpen(false);
              }}
            >
              Rensa
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
}
