import { useEffect, useState } from 'react';
import { Playlist } from '@/types';
import PlayList from './PlayList';
import { getPlaylist } from '@/api/getPlaylist';

export function LinkedPlaylist({ code }: { code: string }) {
  const [playlist, setPlaylist] = useState<Playlist | null>(null);

  async function fetchPlaylist() {
    try {
      const { data } = await getPlaylist({ code });

      setPlaylist(data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchPlaylist();
  }, [code]);

  if (!playlist) {
    return null;
  }

  return <PlayList playlist={playlist} />;
}
