import storage from '@/utils/storage';
import { ConfirmationDialog } from './ConfirmationDialog';

export function ForceDisconnectButton() {
  const handleClick = () => {
    storage.setDeviceId('');
    window.location.reload();
  };

  return <ConfirmationDialog onConfirm={handleClick} ButtonComponent={<button className="z-50 fixed bottom-0 right-0 h-[5hv] w-[5vw]  text-transparent">Clear</button>} />;
}
