import { useEffect, useMemo, useState } from 'react';
import { BookingDisplay } from '@/types';
import { formatDate } from '@/utils/formatDate';
import { formateTime } from '@/utils/formatTime';
import { getBookingDisplay } from '@/api/getBookingDisplay';
import { TDirection } from '../../data/direction';

interface BookingPageItemProps {
  bookable?: { bookableId: number; direction: TDirection };
  hideEndTime: boolean;
  timeThreshold: number;
  forcedTimeThreshold: number;
  hideName: boolean;
  hideExtra: boolean;
  hideTime: boolean;
  hideLocation: boolean;
  hideImage: boolean;
}

// const positions = [
//   { id: 'name', x: 0, y: 0, w: 12, h: 1 },
//   {
//     id: 'extra',
//     x: 0,
//     y: 1,
//     w: 12,
//     h: 1,
//   },
//   {
//     id: 'location',
//     x: 0,
//     y: 2,
//     w: 12,
//     h: 1,
//   },
//   {
//     id: 'time',
//     x: 0,
//     y: 3,
//     w: 12,
//     h: 1,
//   },
// ];

function BookingPageItem({ bookable, hideEndTime, timeThreshold, forcedTimeThreshold, hideName, hideExtra, hideTime, hideLocation, hideImage }: BookingPageItemProps) {
  const [bookings, setBookings] = useState<BookingDisplay[]>([]);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  async function getBookings() {
    try {
      const response = await getBookingDisplay({ bookableId: bookable?.bookableId ?? 0 });
      setBookings((response as any)?.data);
    } catch (error) {}
  }

  useEffect(() => {
    getBookings();

    const interval = setInterval(() => {
      getBookings();
    }, 24 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const currentBooking = useMemo(() => {
    const timeThresholdMs = timeThreshold * 60 * 1000; // 30 minutes in milliseconds
    const forcedTimeThresholdMs = forcedTimeThreshold * 60 * 1000; // 30 minutes in milliseconds

    const bookingsInsideThreshold = bookings?.filter(({ startTime }) => {
      const startTimeMs = +new Date(formatDate(startTime.toString())); // Parse UTC start time
      return startTimeMs - new Date().getTime() <= timeThresholdMs;
    });

    const sortedBookings = bookingsInsideThreshold.sort((a, b) => {
      const aStartTime = +new Date(formatDate(a.startTime.toString()));
      const bStartTime = +new Date(formatDate(b.startTime.toString()));

      if (aStartTime - bStartTime > forcedTimeThresholdMs) {
        return 1;
      }

      return -1;
    });

    return sortedBookings?.[0] ?? null;
  }, [bookings, timeThreshold, forcedTimeThreshold, currentTime]);

  if (!currentBooking) {
    return null;
  }

  return (
    <div className="booking-block w-full h-full relative p-6">
      <div className="booking-name">
        {!hideName && (
          <svg width="100%" height="100%" viewBox={'0 0 1800 120'} preserveAspectRatio="xMinYMid meet" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <text x="50%" y="70" fontSize="100" fill="black" className="font-bold" textAnchor="middle" dominantBaseline="middle">
              {currentBooking?.name ?? 'Namn'}
            </text>
          </svg>
        )}
      </div>
      <div className="booking-extra">
        {!hideExtra && (
          <svg width="100%" height="100%" viewBox={'0 0 1800 70'} preserveAspectRatio="xMinYMid meet" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <text x="50%" y="40" fontSize="40" fill="black" className="font-bold" textAnchor="middle" dominantBaseline="middle">
              {currentBooking?.extra}
            </text>
          </svg>
        )}
      </div>
      <div className="booking-location">{!hideLocation && <div>{'' ?? 'Plats'}</div>}</div>
      <div className="booking-time">
        <svg width="100%" height="100%" viewBox={'0 0 1800 70'} preserveAspectRatio="xMinYMid meet" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <text x="50%" y="40" fontSize="40" fill="black" className="font-bold" textAnchor="middle" dominantBaseline="middle">
            {!hideTime && (
              <>
                {formateTime(new Date(formatDate(currentBooking?.startTime?.toString())).toString())}
                {hideEndTime ? '' : <> - {formateTime(new Date(formatDate(currentBooking?.endTime?.toString())).toString())}</>}
              </>
            )}
          </text>
        </svg>
      </div>
      {!hideImage && (
        <div className="booking-block__time mt-full absolute bottom-6 w-24 h-24 right-6">
          <svg width="100%" height="100%" viewBox={'0 0 24 24'} preserveAspectRatio="xMaxYMid meet" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <image href={currentBooking?.imageUrl ?? 'https://www.google.com/maps/d/thumbnail?mid=1'} x="0" y="0" height="100%" width="100%" />
          </svg>
        </div>
      )}
    </div>
  );
}

export default BookingPageItem;
