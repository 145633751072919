import QRCode from 'react-qr-code';
import PlayList from './components/PlayList';
import { useDisplayConnection } from './hooks/useDisplayConnection';
import { CONFIGURATION_URL } from './config';
import { SignyLogo } from './components/SignyLogo';
import { LinkedPlaylist } from './components/LinkedPlaylist';

function App() {
  const pathName = window.location.pathname;

  const { connection, playlist, hubstate } = useDisplayConnection();

  if (!connection) {
    return (
      <div className="w-full h-screen flex items-center flex-col justify-center space-y-8">
        <div className="w-[300px]">
          <SignyLogo />
        </div>
        {hubstate.connecting ? navigator.onLine && <p className="font-semibold text-lg w-1/2 text-center">Du är inte ansluten till internet...</p> : <p className="font-semibold text-lg w-1/2 text-center">Ansluter...</p>}
      </div>
    );
  }
  // Device is connected but unconfigured
  if (connection.deviceId && !connection?.displayId) {
    return (
      <div className="w-full h-screen flex items-center flex-col justify-center space-y-12">
        <div className="w-[300px]">
          <SignyLogo />
        </div>
        <QRCode value={`${CONFIGURATION_URL}/${connection.deviceId}`} />
        <p className="font-semibold text-lg w-1/2 text-center">
          Skanna QR-koden med din mobiltelefon för att konfigurera skärmen. Om du inte har en QR-läsare kan du öppna följande länk i din webbläsare: <br></br>
          {`${CONFIGURATION_URL}/${connection.deviceId}`}
        </p>
      </div>
    );
  }

  if (pathName !== '/') {
    return <LinkedPlaylist code={pathName.replace(/\/|\/$/g, '')} />;
  }

  if (playlist) {
    return <PlayList playlist={playlist} />;
  }

  return (
    <div className="w-full h-screen flex items-center flex-col justify-center space-y-8 bg-white">
      <div className="w-[300px]">
        <SignyLogo />
      </div>
      {hubstate.connecting ? (
        navigator.onLine && <p className="font-semibold text-lg w-1/2 text-center">Du är inte ansluten till internet...</p>
      ) : hubstate.connected && !playlist ? (
        <p className="font-semibold text-lg w-1/2 text-center">Ingen spellista är vald att spelas på denna skärm..</p>
      ) : (
        <p className="font-semibold text-lg w-1/2 text-center">Ansluter...</p>
      )}
    </div>
  );
}

export default App;
