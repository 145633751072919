import { ENV } from '@/config';
import { useEffect, useRef, useState } from 'react';

interface VideoBlockProps {
  src: string;
  objectFit?: 'contain' | 'cover';
  rotation?: number;
}

const rotationStyle = {
  [180]: {
    transform: 'rotate(180deg)',
  },
  [90]: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'rotate(90deg) translateY(-100%)',
    WebkitTransform: 'rotate(90deg) translateY(-100%)',
    MozTransform: 'rotate(90deg) translateY(-100%)',
    MozTransformOrigin: 'top left',
    WebkitTransformOrigin: 'top left',
    transformOrigin: 'top left',
    width: '100vh',
    height: '100vw',
  },
  [270]: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'rotate(270deg) translateX(-100%)',
    WebkitTransform: 'rotate(270deg) translateX(-100%)',
    MozTransform: 'rotate(270deg) translateX(-100%)',
    MozTransformOrigin: 'top left',
    WebkitTransformOrigin: 'top left',
    transformOrigin: 'top left',
    width: '100vh',
    height: '100vw',
  },
} as const;

function VideoBlock({ src, objectFit, rotation = 0 }: VideoBlockProps) {
  const [videoSrc, setVideoSrc] = useState(src);

  useEffect(() => {
    async function getVideoFromCache(src: string) {
      try {
        if (ENV === 'debug') {
          setVideoSrc(src);
          return;
        }

        // Check if the video is cached in localStorage
        const cachedVideoSrc = localStorage.getItem(src);

        if (cachedVideoSrc) {
          setVideoSrc(cachedVideoSrc);
        } else {
          // If not cached, fetch the video
          const response = await fetch(src);
          if (response.ok) {
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);
            setVideoSrc(objectURL);
            // Cache the video in localStorage
            localStorage.setItem(src, objectURL);
          }
        }
      } catch (error) {
        console.error('Error getting video from cache', error);
      }
    }
    getVideoFromCache(src);
  }, [src]);

  const videRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videRef.current!;
    video?.play();
  }, []);

  return (
    <div className="w-full h-full relative bg-inherit">
      <video
        ref={videRef}
        controls={false}
        autoPlay
        loop
        preload="auto"
        muted
        className="w-full h-full bg-inherit"
        style={{
          objectFit: objectFit ?? 'contain',
          ...(rotation !== 0 ? rotationStyle[rotation.toString() as unknown as keyof typeof rotationStyle] : {}),
        }}
      >
        <source src={videoSrc} type="video/mp4" />
        <source src={videoSrc} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoBlock;
